import { useEffect } from 'react';
import track, { useTracking } from 'react-tracking';
import {
  CardLink,
  Wrapper,
  ImageColumn,
  Content,
  CloseButton,
  ContentCardRow,
} from './styled';
import { useBrazeContentCards } from '@/components/Braze/hooks';
import CrossSolid from '@simplywallst/ui-core/icons/CrossSolid';
import {
  Button,
  Row,
  useTheme,
  Heading,
  Text,
  Box,
} from '@simplywallst/ui-core';
import Carousel, { Slides, Slide } from '@components/Carousel';
import SWSLogo from '@assets/images/logo/sws-logo-no-text.svg';
import { cn } from '@simplywallst/ui-core/next';

interface Props {
  feedType: string;
  anchorTarget?: '_self' | '_blank';
  className?: string;
}

export const BrazeContentCard = ({
  feedType,
  anchorTarget = '_self',
  className,
}: Props) => {
  const {
    contentCards,
    dismissContentCard,
    clickedContentCard,
    cardImpressions,
  } = useBrazeContentCards(feedType);
  const theme = useTheme();
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (contentCards.length > 0) {
      cardImpressions(contentCards);
    }
  });

  return contentCards !== undefined && contentCards.length > 0 ? (
    <ContentCardRow
      slides={contentCards.length}
      className={cn('braze-content-card relative', className)}
    >
      <Carousel
        showArrows={contentCards.length > 1}
        showDots={contentCards.length > 1}
        totalSlides={contentCards.length}
      >
        <Slides>
          {contentCards.map((card) => (
            <Slide key={card.id}>
              <Wrapper>
                <CardLink
                  href={card.url}
                  target={anchorTarget}
                  onClick={() => {
                    clickedContentCard(card);
                    trackEvent({
                      action: 'click',
                      subject: `click-on-content-card-${feedType}`,
                    });
                  }}
                >
                  <Row justifyContent="center" alignItem="flex-start">
                    <ImageColumn
                      colMd={2}
                      justifyContentSm="flex-start"
                      justifyContentLg="center"
                    >
                      {card.imageUrl ? (
                        <img src={card.imageUrl} alt="card" />
                      ) : (
                        <SWSLogo />
                      )}
                    </ImageColumn>
                    <Content colMd={10}>
                      <Box marginBottom="x1" marginTop="x0">
                        <Heading as="h3" typography="smallStrong" color="white">
                          {card.title}
                        </Heading>
                      </Box>
                      <Box marginBottom="x2">
                        <Text
                          typography="xSmall"
                          color="whiteRGBA"
                          opacity={0.5}
                        >
                          {card.description}
                        </Text>
                      </Box>
                      {card.linkText && (
                        <Button styleType="louder" href={card.url}>
                          <Text typography="xSmall">{card.linkText}</Text>
                        </Button>
                      )}
                    </Content>
                  </Row>
                </CardLink>
                <CloseButton
                  styleType="passive"
                  onClick={() => {
                    dismissContentCard(card);
                    trackEvent({
                      action: 'click',
                      subject: `dismiss-content-card-${feedType}`,
                    });
                  }}
                >
                  <CrossSolid fill={theme.color.white} opacity="0.5" />
                </CloseButton>
              </Wrapper>
            </Slide>
          ))}
        </Slides>
      </Carousel>
    </ContentCardRow>
  ) : null;
};

export default track({ page: 'BrazeContentCard' })(
  BrazeContentCard
) as typeof BrazeContentCard;
