import styled from 'styled-components';
import { Row, Column, Button, media } from '@simplywallst/ui-core';

import {
  EmblaDots,
  CarouselWrapper,
  EmblaButtonNext,
  EmblaButtonPrev,
  EmblaDot,
} from '@/components/Carousel/styled';

interface Props {
  slides: number;
}

export const ContentCardRow = styled(Row)<Props>`
  ${EmblaDots} {
    display: none;
  }

  ${EmblaButtonNext} {
    top: unset;
    bottom: 0px;
    right: 8px;
    fill: ${({ theme }) => theme.color.white};
  }

  ${EmblaButtonPrev} {
    top: unset;
    left: unset;
    right: ${({ slides }) => 30 + slides * 16}px;
    bottom: 0px;
    fill: ${({ theme }) => theme.color.white};
  }

  ${CarouselWrapper} {
    padding: 0;
    display: flex;
    flex: 1;
  }

  ${EmblaDot} {
    margin-right: 0;
    margin-left: 0;

    &:after {
      width: 4px;
      height: 4px;
    }
  }
`;

export const CardLink = styled('a')`
  text-decoration: none;
  padding: ${({ theme }) => theme.x1spacePx}px;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.color.almostBlackRGBA(0.7)};
  }
`;

export const Wrapper = styled(Row)`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.color.whiteRGBA(0.7)};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => `${theme.x1spacePx}px`};
  background-color: ${({ theme }) => theme.color.noir};
  padding: ${({ theme }) => theme.x1spacePx}px;
`;

export const ImageColumn = styled(Column)`
  display: flex;
  img {
    max-width: 88px;
    width: 100%;
    border-radius: 12px;

    ${media.mdUp`
      max-width: 180px;
    `}
  }
`;

export const Content = styled(Column)`
  padding-top: ${({ theme }) => `${theme.x2spacePx}px`};
  padding-right: ${({ theme }) => `${theme.x2spacePx}px`};

  ${media.mdUp`
    padding: 0 ${({ theme }) => `${theme.x2spacePx}px`}
      ${({ theme }) => `${theme.x2spacePx}px`}
      ${({ theme }) => `${theme.x2spacePx}px`};
  `}
`;

export const CloseButton = styled(Button)`
  position: absolute;
  right: 5px;
  top: 10px;
`;
