import { useSelector } from 'react-redux';
import { getUi } from '@ducks/ui/selectors';
import loadable from '@loadable/component';
import useUser from '@/hooks/useUser';
import { useUserAccess } from '@/hooks/useUserAccess';
import { getRegistrationFromNewsCookie } from '@/utilities/cookies';
import { useLocation } from '@/hooks/useLocation';
import {
  ROUTE_PARTNER_AVANZA_CLAIM,
  ROUTE_PARTNER_AVANZA_ERROR,
  ROUTE_PARTNER_CLAIM,
  ROUTE_PARTNER_ERROR,
  ROUTE_PORTFOLIO_NEW,
} from '@/constants/routes';

const OnboardingContent = loadable(
  () => import('@/components/Onboarding/components/OnboardingContent'),
  {
    resolveComponent: (c) => c.OnboardingContent,
    ssr: false,
  }
);

function Onboarding() {
  const ui = useSelector(getUi);
  const { data: user } = useUser();
  const { data: access } = useUserAccess();
  const location = useLocation();

  const isRegistrationFromNews =
    user.loggedIn && getRegistrationFromNewsCookie();
  const showModal =
    ((ui.showOnboarding &&
      user.loggedIn &&
      (access.planType === 'premium' || access.planType === 'free') &&
      user.billing.status === 'trialing' &&
      RUNTIME_ENV !== 'server') ||
      isRegistrationFromNews) &&
    // Don't show the onboarding modal when a user first joins by trying to claim a broker linkage
    ![
      ROUTE_PARTNER_CLAIM,
      ROUTE_PARTNER_ERROR,
      ROUTE_PARTNER_AVANZA_CLAIM,
      ROUTE_PARTNER_AVANZA_ERROR,
    ].includes(location.type) &&
    // TODO @ric: remove below condition if the redirect to portfolio/new upon registration experiment is not successful
    //FF mono-redirect-to-new-portfolio
    ![ROUTE_PORTFOLIO_NEW].includes(location.type);

  if (!showModal) {
    return null;
  }

  return <OnboardingContent />;
}

export default Onboarding;
