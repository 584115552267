import loadable from '@loadable/component';
import { useFlag } from '@/features/unleash';
import { usePortfolios } from '@/hooks/portfolio/usePortfolio';

const DemoOnboarding = loadable(() => import('./components/DemoOnboarding'), {
  resolveComponent: (component) => component.DemoOnboarding,
  ssr: false,
});

const useShowPortfolioOnboarding = () => {
  const showPortfolioExperiment =
    useFlag({ name: 'mono-profile-portfolio-box' }).variant.name === 'variant';
  const { data: newPortfolios = [], isFetched: newPortfoliosIsFetched } =
    usePortfolios();

  if (!showPortfolioExperiment) return false;
  if (!newPortfoliosIsFetched) return false;
  if (newPortfolios.length > 0) return false;

  return true;
};

export const PortfolioOnboardingDesktop = () => {
  const showPortfolioOnboarding = useShowPortfolioOnboarding();
  if (!showPortfolioOnboarding) return;

  return (
    <div className="hidden lg:block">
      <DemoOnboarding />
    </div>
  );
};

export const PortfolioOnboardingMobile = () => {
  const showPortfolioOnboarding = useShowPortfolioOnboarding();
  if (!showPortfolioOnboarding) return;

  return (
    <div className="-mt-8 md:-mt-11 lg:hidden [.i18n-disclaimer~div_&]:mt-0">
      <DemoOnboarding />
    </div>
  );
};
